import React, { useState, useEffect, useRef } from 'react';
import xtraBG from '../images/xtra-bg.jpg';
import SafeAreaHeader from '../components/SafeAreaHeader';
import NavigationMenuItem from '../components/NavigationMenuItem';
import BackButton from '../components/BackButton';
import renderToastError from '../renderFunctions/renderToastError';
import AccountIcon from '../components/AccountIcon';
import CircularProgressBar from '../components/CircularProgressBar';
import SlideUpDrawer from '../components/SlideUpDrawer';
import Api from '../components/Api';
import notifySwift from '../constants/notifySwift';
import { useNavigate } from 'react-router-dom';
import isCasual from '../constants/isCasual';
import { useCustomer } from '../contexts/CustomerContext';
import blueCamera from '../images/blueCamera.png';
import { Bounce, toast } from 'react-toastify';

const You = () => {
  const navigate = useNavigate();
  const { customer, customerMembership, customerMembershipProductName, getCustomer, customerLoading } = useCustomer();
  const [loading, setLoading] = useState(true);
  const [showDrawer, setShowDrawer] = useState(false);
  const [showPPDrawer, setShowPPDrawer] = useState(false);
  const [profileImageLoading, setProfileImageLoading] = useState(false);
  const [sessionCount, setSessionCount] = useState(0);
  const fileInputRef = useRef(null); // Add reference to the file input element

  let menuItems = [];

  if (customerMembership) {
    menuItems.push({
      title: 'Membership details',
      location: '/membership-details',
    });
  }

  menuItems = [
    ...menuItems,
    {
      title: 'Your session packs',
      location: '/customer-session-packs',
    },
    {
      title: 'Account details',
      location: '/your-details',
    },
    {
      title: 'Billing details',
      location: '/billing-details',
    },
    {
      title: 'Overstay charges',
      location: '/overstay-charges',
    },
    {
      title: 'Change password',
      location: '/change-password',
    },
    {
      title: 'Permissions',
      location: '/permissions',
      disabled: isCasual,
    },
    {
      title: 'Delete account data',
      onClick: () => setShowDrawer(true),
    },
    {
      title: 'Membership agreement',
      location: '/member-onboarding/1',
    },
    {
      title: 'Privacy policy',
      onClick: () =>
        notifySwift({
          function: 'openInternalLink',
          data: {
            url: 'https://xtraclubs.au/privacy-policy/?hideDetails=true',
          },
        }),
    },
    {
      title: 'Refund policy',
      onClick: () =>
        notifySwift({
          function: 'openInternalLink',
          data: {
            url: 'https://xtraclubs.au/refund-policy/?hideDetails=true',
          },
        }),
    },
    { title: 'Logout', onClick: () => navigate('/logout') },
  ];

  const deleteAccount = async () => {
    await Api('/customer/details/mongo', 'PUT', {
      flaggedForDeletion: true,
    });
    localStorage.clear();
    renderToastError({
      message:
        'Your account has been flagged for deletion and an email has been sent with further information. You are unable to continue. If this is a mistake, please contact us.',
      onClose: () => {
        notifySwift({
          function: 'logout',
        });
      },
    });
  };

  const getCustomerSessionsCount = async () => {
    const count = await Api('/customer/sessions', 'GET', null);
    if (count.status === 200) {
      console.log('count.data: ', count.data);
      setSessionCount(count.data.customerSessions);
    }
  };

  const saveProfileImage = async (selectedFile) => {
    setProfileImageLoading(true);
    setShowPPDrawer(false);
    let formData = new FormData();

    if (selectedFile !== null) {
      formData.append('profile_picture', selectedFile);
    }

    let response = await Api(`/customer/details`, 'PUT', formData, null, '/v1', false, true);
    console.log('formData: ', selectedFile);
    console.log('response: ', response);

    if (response.status === 200) {
      let result = response.data;
      if (result?.success) {
        getCustomer({ update: true });
        setProfileImageLoading(false);
        toast.success('You successfully updated your profile details', {
          position: 'top-center',
          style: { top: '5vh', marginLeft: 20, marginRight: 20 },
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
          transition: Bounce,
        });
      }
    } else {
      setProfileImageLoading(false);
      renderToastError({
        message: response.data.message,
      });
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    console.log('Selected file:', selectedFile);
    saveProfileImage(selectedFile);
  };

  const openFilePicker = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  useEffect(() => {
    getCustomer();
    getCustomerSessionsCount();
    setLoading(false);
  }, [getCustomer]);

  return (
    <div
      className="flex flex-col bg-xtraNavy min-h-screen bg-no-repeat bg-center bg-cover overflow-y-scroll max-h-[60vh]"
      style={{ backgroundImage: `url(${xtraBG})` }}
    >
      <div className="rounded-b-[40px] bg-xtraNavy py-6">
        <SafeAreaHeader />
        <div className="flex flex-row items-center px-6 mb-6 justify-between">
          <div className="flex flex-row items-center">
            <BackButton />
            <h6>Account</h6>
          </div>
        </div>
        {loading || customerLoading ? (
          <CircularProgressBar />
        ) : (
          <>
            <div className="w-full flex flex-col items-center mb-3">
              <div className="relative">
                {profileImageLoading ? (
                  <CircularProgressBar />
                ) : (
                  <AccountIcon yourDetails={customer} size={80} fontSize={36} noPhoto={false} />
                )}
                <img
                  src={blueCamera}
                  alt="camera"
                  className="w-8 absolute top-6 right-[-15px]"
                  onClick={() => setShowPPDrawer(true)}
                />
              </div>
              <p className="mt-2 text-[22px] font-extrabold">
                {customer?.first_name} {customer?.last_name}
              </p>
              <p className="text-rg mt-2">{customerMembershipProductName}</p>
              {/* <div className="flex flex-row gap-2 mt-5 w-[90%]">
                <div className="flex flex-col items-center p-4 bg-xtraWhite10 rounded-lg border border-xtraWhite30 flex-1">
                  <p className="text-3xl" style={{ fontFamily: 'BBS Bold' }}>
                    {customer.memberNumber}
                  </p>
                  <p className="text-sm text-xtraWhite50">Member Number</p>
                </div>
                <div
                  onClick={() => navigate('/customer-past-sessions')}
                  className="flex flex-col items-center p-4 bg-xtraWhite10 rounded-lg border border-xtraWhite30 flex-1"
                >
                  <p className="text-3xl" style={{ fontFamily: 'BBS Bold' }}>
                    {sessionCount}
                  </p>
                  <p className="text-sm text-xtraWhite50">Total Sessions</p>
                </div>
              </div> */}
            </div>
          </>
        )}
      </div>
      {
        (!loading || !customerLoading) && (
          <div className="w-full flex flex-col pb-8">
            {menuItems.map((menuItem, i) => (
              menuItem.disabled ? null : (
                <NavigationMenuItem
                  key={i}
                  index={i}
                  title={menuItem.title}
                  location={menuItem.location}
                  onClick={menuItem.onClick}
                />
              )
            ))}
          </div>
        )
      }
     
      <SlideUpDrawer
        show={showDrawer}
        setShow={setShowDrawer}
        action={deleteAccount}
        actionText={'Delete Account Data'}
        actionHeading={'Are you sure you want to delete your account data?'}
        message={
          'By continuing your data will be scheduled to be deleted. Please note this is not the same as cancelling your account.'
        }
        actionClass={'redButton'}
      />

      {/* New Drawer for Adding or Taking a Photo */}
      <SlideUpDrawer
        show={showPPDrawer}
        setShow={setShowPPDrawer}
        action={openFilePicker}
        actionRightAway={true}
        doNotCloseDrawer={true}
        actionText={'Choose or Take Photo'}
        actionHeading={'Profile Photo Options'}
        message={'Choose an option to add a profile photo.'}
      />

      {/* Hidden File Input */}
      <input type="file" accept="image/*" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
    </div>
  );
};

export default You;
