import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import BackButton from '../components/BackButton';
import xtraBg from '../images/xtra-bg.jpg';
import Api from '../components/Api';
import notifySwift from '../constants/notifySwift';
import SafeAreaHeader from '../components/SafeAreaHeader';
import renderToastError from '../renderFunctions/renderToastError';

const ClubSurvey = ({ title, confirmations, nextStep, stepNumber, terms }) => {
  const navigate = useNavigate();
  const [responses, setResponses] = useState(Array(confirmations.length).fill(null));
  const [isNextDisabled, setIsNextDisabled] = useState(stepNumber === 5 ? false : true);
  const errorRefs = useRef([]); // Refs for error message divs
  const isFinalStep = nextStep === '/welcome';

  const updateRequirement = async () => {
    const onboardingResponse = await Api('/customer/requirement', 'PATCH', {
      requirement: 'memberOnboarding',
      value: true,
    });
    const agreementResponse = await Api('/customer/requirement', 'PATCH', {
      requirement: 'memberAgreement',
      value: true,
    });

    if (onboardingResponse.status === 200 && agreementResponse.status === 200) {
      navigate(nextStep);
    } else {
      renderToastError({ message: 'Failed to complete survey. Please try again or contact us for assistance' });
    }
  };

  const handleNextClick = () => {
    if (isFinalStep) {
      updateRequirement();
    } else {
      navigate(nextStep);
    }
  };

  const handleResponse = (index, value) => {
    if (stepNumber !== 5) {
      const updatedResponses = [...responses];
      updatedResponses[index] = value;
      setResponses(updatedResponses);

      // Check if all responses are filled
      const allResponsesFilled = updatedResponses.every((response) => response !== null);
      // Check if there is any "X" response
      const isAnyXResponse = updatedResponses.includes('X');

      // Update isNextDisabled based on all responses filled and presence of "X"
      setIsNextDisabled(stepNumber !== 5 && (!allResponsesFilled || isAnyXResponse));

      // Scroll to specific error div if 'X' is selected
      if (value === 'X' && errorRefs.current[index]) {
        setTimeout(() => {
          const errorElement = errorRefs.current[index];
          const errorRect = errorElement.getBoundingClientRect();
          const isFullyVisible = errorRect.bottom <= window.innerHeight && errorRect.top >= 0;

          if (!isFullyVisible) {
            errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        }, 500);
      }
    }
  };

  // Effect to scroll the error message into view when it is first shown
  useEffect(() => {
    const firstXIndex = responses.findIndex((response) => response === 'X');
    if (isNextDisabled && firstXIndex !== -1 && errorRefs.current[firstXIndex]) {
      const errorElement = errorRefs.current[firstXIndex];
      const errorRect = errorElement.getBoundingClientRect();
      const isFullyVisible = errorRect.bottom <= window.innerHeight && errorRect.top >= 0;

      if (!isFullyVisible) {
        errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [isNextDisabled, responses]);

  return (
    <div
      className={`bg-xtraNavy min-h-screen bg-no-repeat bg-center bg-cover flex flex-col justify-start items-center px-4`}
      style={{ backgroundImage: `url(${xtraBg})` }}
    >
      <SafeAreaHeader />
      {/* Static Header */}
      <div className="w-full max-w-md mx-auto p-4 flex items-center">
        {stepNumber === 1 ? (
          <>
            <BackButton />
            <h5 className="text-lg text-white ml-2 flex-1">Club Rules</h5>
          </>
        ) : (
          <>
            <BackButton />
            <h5 className="text-lg text-white ml-2 flex-1">Back</h5>
          </>
        )}
        <span className="text-white">{stepNumber}/5</span>
      </div>

      {/* White Banner for Current Step */}
      <div className="w-full max-w-md mx-auto mt-1 bg-white rounded-t-lg p-2 flex justify-center items-center">
        <span className="text-black font-bold">
          <span className="bg-black text-white text-sm px-3 py-1 rounded-full mr-1">{stepNumber}</span> {title}
        </span>
      </div>

      {/* Scrollable container for confirmations */}
      <div
        className={`overflow-y-auto w-full max-w-md mx-auto p-4 bg-black/70 rounded-b-lg shadow-md ${
          isNextDisabled ? 'max-h-[75vh]' : 'max-h-[70vh]'
        }`}
      >
        {confirmations.map((confirmation, index) => (
          <div key={index} className="mb-6">
            <p className="text-white mb-2 font-bold text-xl">{confirmation}</p>
            {/* Conditionally render buttons only if stepNumber is not 5 */}
            {stepNumber !== 5 && (
              <div className="flex gap-2 mt-4 justify-center items-center">
                <button
                  onClick={() => handleResponse(index, 'Agree')}
                  className={`flex items-center justify-center gap-1 py-2 px-4 w-[90%] mr-5 rounded ${
                    responses[index] === 'Agree'
                      ? 'bg-aquaBlue text-white'
                      : 'border bg-transparent text-white border-white'
                  }`}
                >
                  <span>✓</span> Agree
                </button>
                <button
                  onClick={() => handleResponse(index, 'X')}
                  className={`flex items-center justify-center gap-1 py-2 px-2 rounded-full ${
                    responses[index] === 'X' ? 'bg-red-500 text-white' : 'border bg-transparent text-white border-white'
                  }`}
                  style={{ width: '40px', height: '40px' }} // Smaller size for X button
                >
                  ✕
                </button>
              </div>
            )}
            {/* Error message for this specific confirmation */}
            {responses[index] === 'X' && isNextDisabled && (
              <div
                ref={(el) => (errorRefs.current[index] = el)} // Assign ref to this specific error message div
                className="w-full max-w-md mx-auto p-4 z-10 flex justify-center"
              >
                <div className="bg-gray-800 text-white rounded-lg p-4 flex items-center gap-2">
                  <span className="text-yellow-500">⚠️</span>
                  <span>
                    You must agree to all of our club rules in order to proceed. If you are unsure, please{' '}
                    <span
                      onClick={() => {
                        notifySwift({
                          function: 'openInternalLink',
                          data: {
                            url: `https://xtraclubs.au/contact/?hideDetails=true`,
                          },
                        });
                      }}
                      className="text-blue-300 underline"
                    >
                      contact us
                    </span>
                    .
                  </span>
                </div>
              </div>
            )}
          </div>
        ))}
        {stepNumber === 5 && (
          <div className="overflow-y-auto w-full max-w-md mx-auto p-4 bg-white/20 rounded-b-lg shadow-md max-h-[40vh]">
            <div className="flex break-words flex-nowrap text-wrap	w-full">
              <p className="mb-5 w-full" dangerouslySetInnerHTML={{ __html: terms }}></p>
            </div>
          </div>
        )}

        {/* Next button when disabled */}
        {isNextDisabled && (
          <div className="w-full max-w-md mx-auto p-4 flex justify-center">
            <button
              onClick={handleNextClick}
              className={`w-full py-2 px-4 rounded-full bg-aquaBlue text-white cursor-not-allowed opacity-50`}
              disabled={isNextDisabled}
            >
              {isFinalStep ? 'Continue' : 'Next'}
            </button>
          </div>
        )}
      </div>

      {/* Next button when enabled */}
      {!isNextDisabled && (
        <div className="fixed bottom-4 w-full max-w-md mx-auto p-4 z-10 flex justify-center">
          <button onClick={handleNextClick} className="w-full py-2 px-4 rounded-full bg-aquaBlue text-white">
            {isFinalStep ? 'Continue' : 'Next'}
          </button>
        </div>
      )}
    </div>
  );
};

export default ClubSurvey;
